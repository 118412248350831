body{
  --bs-font-sans-serif: Lato,
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol";
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-bg: #171B34;
}

header a.moniker{
  margin-left: 15px;
}

.clipboard.copied{
  text-decoration: underline;
}

.btn-circle-sm {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 0.9rem;
}

.btn-circle-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 1.1rem;
}

.btn-circle-xl {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 1.3rem;
}

code {
  padding: 0.5rem;
  line-height:1.5rem;
}

code p{
  margin-bottom: 0.5rem;
}

.favourite-on .on,
.favourite-off .off {
  display: block !important;
}

.favourite-on .off,
.favourite-off .on {
  display: none !important;
}

@media (hover: hover) {
  .favourite-on:hover .off,
  .favourite-off:hover .on{
    display: block !important;
  }

  .favourite-on:hover .on,
  .favourite-off:hover .off {
    display: none !important;
  }
}

pre.pre-wrap {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
 }
